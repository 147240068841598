import {
  default as Icon,
  IconSecurityShieldCross,
  IconShapeHeart,
  IconShapeLightningBolt,
  IconTechnologyBrowserLight,
  IconTechnologyElementSelector,
  IconTechnologyImageScreenshot,
  IconTechnologyInfinityLoop,
  IconViewPieChart,
  IconObjectGraduationCap,
  IconObjectTag,
  IconObjectBook,
  IconActionQuestionMarkOutline,
  IconUserGeneralOutline,
  IconTimeCalendarDay,
  IconGeneralChatBubble,
  IconSocialYoutubeSolid,
  IconSocialGithubSolid,
  IconSocialLinkedinSolid,
  IconSocialTwitterSolid,
  IconSocialFacebookSolid,
  IconSocialDiscordSolid,
} from '@cypress-design/vue-icon'
import { defineComponent, h } from 'vue'

export const iconsMap = {
  'technology-browser-light': IconTechnologyBrowserLight,
  'technology-element-selector': IconTechnologyElementSelector,
  'shape-heart': IconShapeHeart,
  'security-shield-cross': IconSecurityShieldCross,
  'shape-lightning-bolt': IconShapeLightningBolt,
  'technology-image-screenshot': IconTechnologyImageScreenshot,
  'view-pie-chart': IconViewPieChart,
  'technology-infinity-loop': IconTechnologyInfinityLoop,
  'object-graduation-cap': IconObjectGraduationCap,
  'object-tag': IconObjectTag,
  'object-book': IconObjectBook,
  'action-question-mark-outline': IconActionQuestionMarkOutline,
  'user-general-outline': IconUserGeneralOutline,
  'time-calendar-day': IconTimeCalendarDay,
  'general-chat-bubble': IconGeneralChatBubble,
  'social-github-solid': IconSocialGithubSolid,
  'social-youtube-solid': IconSocialYoutubeSolid,
  'social-linkedin-solid': IconSocialLinkedinSolid,
  'social-twitter-solid': IconSocialTwitterSolid,
  'social-facebook-solid': IconSocialFacebookSolid,
  'social-discord-solid': IconSocialDiscordSolid,
} as unknown as Record<string, typeof Icon>

export default defineComponent({
  // eslint-disable-next-line vue/require-prop-types
  props: ['name', 'strokeColor', 'fillColor', 'hoverStrokeColor', 'hoverFillColor', 'interactiveColorsOnGroup'],
  setup(props: { name: string } & Omit<InstanceType<typeof Icon>['$props'], 'name' | 'class'>) {
    return () => h(iconsMap[props.name], props as any)
  },
})
