<script lang="ts" setup>
import { computed } from 'vue'
import Button from '@cypress-design/vue-button'
import ProductSubmenu from './NavbarDropdowns/ProductSubmenu.vue'
import Submenu from './NavbarDropdowns/Submenu.vue'
import { type SidebarProps, type SubmenuProps } from './NavbarDropdowns/types'

import PRODUCT from './constants/product.json'
import COMMUNITY from './constants/community.json'
import COMPANY from './constants/company.json'
import DOCS from './constants/docs.json'
import COMMUNITY_SIDEBAR from './constants/community-sidebar.json'
import SOCIAL_LINKS from '@/constants/social.json'
import DOCS_SIDEBAR from './constants/docs-sidebar.json'

const submenuProps: Array<{
  name: string
  submenu: SubmenuProps
  sidebar: SidebarProps[]
  mobileVerticalSidebar?: boolean
}> = [
  {
    name: 'Docs',
    submenu: DOCS,
    sidebar: DOCS_SIDEBAR,
  },
  {
    name: 'Community',
    submenu: COMMUNITY,
    sidebar: COMMUNITY_SIDEBAR,
    mobileVerticalSidebar: true,
  },
  {
    name: 'Company',
    submenu: COMPANY,
    sidebar: SOCIAL_LINKS.map((link) => ({ ...link, target: '_blank' })),
  },
]

const props = defineProps<{
  currentPath: string
}>()

const pricingActive = computed(() => props.currentPath === '/pricing')

const cloudUrl = import.meta.env.PUBLIC_CLOUD_URL
</script>

<template>
  <ul
    class="relative flex flex-col divide-y-[1px] divide-transparent px-[16px] py-[16px] text-gray-700 menu:flex-row menu:items-center menu:justify-around menu:divide-y-0 menu:p-0 lg:static"
  >
    <li>
      <ProductSubmenu name="Product" :submenus="PRODUCT" :current-path="currentPath" />
    </li>
    <li v-for="{ name, submenu, sidebar, mobileVerticalSidebar } in submenuProps" :key="name">
      <Submenu v-bind="{ name, submenu, sidebar, mobileVerticalSidebar, currentPath }" />
    </li>
    <li>
      <a
        :class="`nav-bar-link menu:nav-bar-link-hover py-[8px] menu:mx-[20px] menu:py-0 ${
          pricingActive ? 'active from-gray-300 to-gray-300' : ''
        }`"
        href="/pricing"
      >
        Pricing
      </a>
    </li>
    <li class="flex w-full gap-[16px] py-[32px] menu:hidden">
      <Button
        class="flex-1 justify-center !text-teal-500"
        variant="outline-light"
        :href="`${cloudUrl}/login`"
        target="_blank"
      >
        Log in
      </Button>
      <Button class="flex-1 justify-center" variant="jade-light" :href="`${cloudUrl}/signup`" target="_blank">
        Sign up
      </Button>
    </li>
  </ul>
</template>

<style>
.mobile-navbar-open {
  @apply overflow-y-scroll;
}
.mobile-navbar-open .nav-bar {
  @apply !top-[60px];
}
</style>
