<script lang="ts" setup>
import Icon from './submenuIcon'
import type { NavColumnsCollection, NavLinkImage } from './types'
import Dropdown from './Dropdown.vue'
import HeaderLink from './HeaderLink.vue'
import MenuLink from './MenuLink.vue'

defineProps<{
  name: string
  currentPath: string
  submenus: {
    title: string
    description: string
    href?: string
    target?: string
    image: {
      title: string
      url: string
    }
    navColumnsCollection: NavColumnsCollection<NavLinkImage>
  }[]
}>()
</script>

<template>
  <Dropdown
    :name="name"
    :href="submenus[0].href ?? '#'"
    modal-classes="menu:left-[-12px] lg:left-[-32px] md:w-[600px]"
    :active="['/app'].includes(currentPath)"
    client:idle
  >
    <template
      v-for="(
        { image: { url: srcStr }, title, description, href, target, navColumnsCollection: { items: navColumns } }, index
      ) in submenus"
      :key="href"
    >
      <hr v-if="index > 0" class="block border-gray-50 sm:mx-[40px]" />
      <div class="py-[32px] sm:px-[32px]" data-cy="submenu">
        <HeaderLink v-bind="{ title, description, href, target, srcStr }" />

        <ul
          v-for="({ navLinksCollection: { items: links } }, colIndex) in navColumns"
          :key="colIndex"
          class="grid grid-cols-2 gap-y-3"
        >
          <li v-for="link in links" :key="link.title">
            <MenuLink :href="link.href">
              <Icon
                :key="`gray-sub-${link.href}`"
                :name="link.icon"
                class="mx-[12px] shrink-0 max-sm:hidden"
                stroke-color="gray-500"
                fill-color="gray-100"
                hover-stroke-color="teal-500"
                hover-fill-color="jade-200"
                interactive-colors-on-group
              />
              <Icon
                :key="`teal-sub-${link.href}`"
                :name="link.icon"
                class="mx-[12px] shrink-0 sm:hidden"
                stroke-color="teal-500"
                fill-color="jade-200"
              />
              <span class="font-secondary text-[14px] font-normal leading-[20px]">{{ link.title }}</span>
            </MenuLink>
          </li>
        </ul>
      </div>
    </template>
  </Dropdown>
</template>
