<script lang="ts" setup>
import { type SVGAttributes, type FunctionalComponent, computed } from 'vue'
import AnimatedArrow from '@/components/Vue/AnimatedArrow.vue'

const props = defineProps<{
  title: string
  description: string
  target?: string
  href?: string
  srcStr: string
}>()

const iconsRaw = import.meta.glob('./assets/**/*.svg', { query: 'component', eager: true })
const icons = Object.entries(iconsRaw).reduce(
  (acc, [key, value]) => {
    const val = value as { default: FunctionalComponent<SVGAttributes> }
    acc[key.replace('./assets/', '').replace('.svg', '')] = val.default as FunctionalComponent<SVGAttributes>
    return acc
  },
  {} as Record<string, FunctionalComponent<SVGAttributes>>,
)

const Icon = computed(() => icons[props.srcStr])
</script>

<template>
  <a class="group mb-6 flex flex-row gap-4" :href="href" :target="target">
    <span class="flex h-10 w-10 items-center justify-center rounded bg-jade-50">
      <Icon class="h-[24px] w-[24px]" />
    </span>
    <span class="flex flex-col">
      <span
        class="flex items-center font-primary text-[16px] font-semibold leading-[24px] text-teal-500 group-hocus:text-teal-400 sm:text-teal-600 sm:group-hocus:text-teal-500"
      >
        {{ title }}
        <AnimatedArrow />
      </span>
      <span class="font-secondary text-[14px] font-normal leading-[20px]">{{ description }}</span>
    </span>
  </a>
</template>
